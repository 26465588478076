import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

interface Props extends PageProps {
    data: {
        sanityImprint: {
            sections: {
                heading: string
                text: string
            }[]
        }
    }
}

const Impressum: React.FC<Props> = ({
    data: {
        sanityImprint: { sections }
    }
}) => {
    return (
        <div>
            <Layout>
                <SEO title="Was Wa(h)r - Impressum" />
            </Layout>

            <div className="legal">
                {sections.map((section, i) => (
                    <div key={i} className="legal-section">
                        <h3>{section.heading}</h3>
                        <p>{section.text}</p>
                    </div>
                ))}
            </div>

            <Footer />
        </div>
    )
}

export const query = graphql`
    query {
        sanityImprint {
            sections {
                heading
                text
            }
        }
    }
`

export default Impressum
